import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/the-pragmatic-guide-to-defending-drupal",
  "date": "16th March 2023",
  "title": "The Pragmatic Guide to Defending Drupal",
  "summary": "Five key areas to invest effort and keep your applications secure.",
  "author": "Nick Schuch",
  "tag": "Case Study",
  "tagColor": "green",
  "tags": [{
    "name": "presentation"
  }, {
    "name": "scaling"
  }, {
    "name": "case study"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Defending Drupal from malicious actors is difficult since it requires a deep knowledge of the application, hosting platform,
and development cycle, all of which are rapidly evolving with the Cloud Native landscape.`}</p>
    <p>{`Listed below are 5 key areas where the Skpr platform team is focusing to keep our infrastructure secure.`}</p>
    <h3>{`Look to standards for industry alignment`}</h3>
    <ul>
      <li parentName="ul">{`Recommend `}<a parentName="li" {...{
          "href": "https://www.iso.org/isoiec-27001-information-security.html"
        }}>{`ISO/IEC 27001`}</a>{` for guidance`}</li>
      <li parentName="ul">{`Target the most relevant items first instead of all at once`}</li>
    </ul>
    <h3>{`Develop a one-page Incident Response Plan answering these questions`}</h3>
    <ul>
      <li parentName="ul">{`Who do you contact when an incident occurs?`}</li>
      <li parentName="ul">{`Can you restore your site?`}</li>
      <li parentName="ul">{`What to do when you have been compromised?`}</li>
    </ul>
    <h3>{`Implement Static Application Security Testing`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/aquasecurity/trivy"
        }}>{`Trivy`}</a></li>
      <li parentName="ul">{`Integrate with existing CI/CD pipeline`}</li>
      <li parentName="ul">{`Works with more than just container images`}</li>
      <li parentName="ul">{`Also consider `}<a parentName="li" {...{
          "href": "https://snyk.io"
        }}>{`Snyk`}</a>{` or `}<a parentName="li" {...{
          "href": "https://github.com/features/security"
        }}>{`Github Security Scanning`}</a></li>
    </ul>
    <h3>{`Implement Dynamic Application Security Testing`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.stackhawk.com"
        }}>{`StackHawk`}</a></li>
      <li parentName="ul">{`Integrate with existing CI/CD pipeline`}</li>
      <li parentName="ul">{`Has a great pricing model (Seats vs Domains)`}</li>
    </ul>
    <h3>{`Implement Threat Detection`}</h3>
    <ul>
      <li parentName="ul">{`Amazon GuardDuty`}</li>
      <li parentName="ul">{`Azure Advanced Threat Protection`}</li>
      <li parentName="ul">{`Google Cloud Platform: Security Command Center`}</li>
    </ul>
    <p>{`The following video provides further detailed technical insights into how Skpr pragmatically keeps its platform and clients secure.`}</p>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="jFoESjk6gvE" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '979'
      }
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      